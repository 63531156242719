.btn-primary {
  background-color: $primary-color; 
  border-color: $primary-color; 
  font-weight: 700;
  box-shadow: none; 
}

.btn-primary-outline {
  background-color: transparent; 
  border: 2px solid $primary-color; 
  color: $primary-color;  
  text-transform: initial;
  box-shadow: none;
  &:hover, &:active, &:focus { 
    color: $primary-color; 
  }
}

.btn-secondary {
  background-color: $primary-contrast-color; 
  color: $mid-gray; 
  display: flex; 
  align-items: center;
  justify-content: center;
  box-shadow: none; 
  &:hover, &:active, &:focus {
    background-color: $secondary-contrast-color; 
    color: $mid-gray; 
  }
}

.btn-tertiarity {
  background-color: $secondary-color; 
  border-color: $secondary-color; 
  color: #fff; 
  font-weight: 700;
  box-shadow: none; 
  &:hover, &:active, &:focus { 
    // color: $primary-color; 
    color: #fff; 
  }
}

.btn-link {
  color: $gray-dark; 
  &:hover {
    background-color: transparent; 
    box-shadow: none; 
  }
}