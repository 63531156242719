.searchForm {
  background-color: #fff; 
  padding: 5px 10px; 
  border: 1px solid #E5E5E5;  
  margin-left: 20px; 
  border-radius: 10px; 
  width: 100%; 

  .form-control {
    border-color: transparent;
  }
  
  .input-group > .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0 0 0 1px transparent;
  }
  
  .btn-link {
    &:hover, &:active, &.active, &:focus, &.focus, &:active:focus, &.active:focus {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

// contentBuscador
.contentBuscador {
  box-shadow: none;
  margin-bottom: 20px; 
  .searchForm {
    margin-left: 0; 
    border: none; 
    padding: 10px;
  }
}