.section-empresa, .section-colaborador {
	margin-bottom: 20px; 
	border: 1px solid $light-gray; 
	padding: 20px;  
}

.fileInput-referencia {
	margin-bottom: 20px; 
	border: 1px solid $light-gray; 
	padding: 20px;  
}