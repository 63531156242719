.customTable{

  .customTable-head {
    display: grid; 
    grid-template-columns: 1fr;
    align-items: center;
    padding: 1rem 1.4rem;
    border-bottom: 1px solid $light-gray; 
  }

  .customTable-item {
    .customTable-row {
      &:hover {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        background: $light-gray
         !important; 
      }
    }
    &:nth-of-type(odd) { 
      .customTable-row {
        background-color: $bg-gray; 
      }
    }
  }

  .customTable-row {
    display: grid; 
    grid-template-columns: 1fr;
    align-items: center;
    padding: 1rem 1.4rem;
    border-bottom: 1px solid $light-gray; 
  }
  .item-title {
    font-weight: 600;
  }
  .item-info {
    font-size: 14px; 
    &:first-child {
      font-weight: 600;
    }
  }

  .customTable-collapseContent {
    .userContent {
      padding: 20px; 
      background-color: $bg-gray; 
    }
    .userInfo {
      padding: 20px; 
      background-color: $primary-contrast-color;
    }

    .userInfo-row {
      display: grid; 
      grid-template-columns: 1fr;
    }
  }

  .userInfo-form {
    margin-top: 20px; 
    .form-row {
      display: flex; 
      flex-direction: row;
      align-items: center;
      column-gap: 15px;
      &:not(:last-of-type) {
        margin-bottom: 10px; 
      }
      .md-input {
        margin-bottom: 0;
      }
      .form-title {
        margin-bottom: 5px; 
        font-weight: 600;
      }
    }
    .form-changeRoles {
      flex-direction: column;
      align-items: flex-start;
    }
    .form-check-list {
      display: flex; 
      column-gap: 25px;
    }
  }
}

@media (min-width: 991px) {
  .customTable{
    .customTable-head {
      display: grid; 
      grid-template-columns: 12% 25% 20% 28% 10% 5%;
    }
    .customTable-row {
      display: grid; 
      grid-template-columns: 12% 25% 20% 28% 10% 5%;
    }

    .customTable-collapseContent {
      .userInfo-row {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .item-info {
          font-weight: 500;
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}