.sectionHeader {
  display: flex; 
  justify-content: space-between;
  align-items: center; 
}

.actions {
  display: flex; 
  flex-direction: row;
  column-gap: 20px;
}

.actionTitle {
  display: flex; 
  flex-direction: row;
  column-gap: 5px;
}