.modal-custom {
  .modal-header {
    border-bottom: 0; 
  }

  .modal-title {
    color: $primary-color; 
  }

  form, .modal-body {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 15px;
  }
  .modal-col {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    grid-row: auto;
    width: 100%; 

    .btn {
      margin-bottom: 15px; 
    }
    .form-check {
      margin-bottom: 10px; 
    }
  }

  .form-col {
    border: 1px solid red; 
  }

  .modal-alerts {
    text-align: center;
    display: flex; 
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; 
    margin-top: -20px; 
  }

  .message-text {
    font-size: 21px; 
    margin-top: 10px; 
  }

  .icon-confirm {
    color: $success-color;
    font-size: 120px; 
  }
  .icon-cancel {
    color: $alert-color; 
    font-size: 120px; 
  }
  .icon-warning {
    color: $warning-color; 
    font-size: 120px; 
  }
}

.lote-detail {
  background-color: $primary-contrast-color; 
  width: 80%;
  padding: 20px;
  border-radius: 12px; 
  margin: 20px 0; 
  .lote-text {
    margin-bottom: 0;
    font-size: 18px; 
  }
}

.detail-error {
  width: 80%; 
  text-align: left;
  margin-bottom: 20px; 
  .detail-title {
    text-transform: uppercase;
    color: #696F79; 
    font-weight: 600;
  }
  .list-error {
    padding: 5px 20px; 
    height: 180px; 
    overflow: auto; 
    .error-text {
      border-bottom: 1px solid #CECECE; 
      padding-bottom: 10px; 
      margin-bottom: 10px; 
      &:last-of-type {
        margin-bottom: 0; 
        padding-bottom: 0; 
      }
    }
  }
}

#modaEditarUsuarios, #modalRegistrarUsuarios {
  .modal-col {
    justify-content: start;
  }
  .form-check {
    margin-bottom: 10px; 
  }
}

.datosLotes {
  background-color: $primary-contrast-color; 
  border-radius: 12px;
  padding: 20px; 
  margin-bottom: 15px; 

  .lote-row {
    display: grid; 
    grid-template-columns: 1fr 150px;
    column-gap: 15px; 
    margin-top: 10px; 
    .lote-text {
      display: block; 
      margin-bottom: 0; 
      strong {
        display: block; 
      }
    }
  }
}

@media (min-width: 520px) {
  .modal-custom {
    form {
      display: flex; 
      column-gap: 15px;  
      // flex-direction: column;
    }
    #form-apariencia {
      display: grid; 
      grid-template-columns: 35% auto;
    }
    .modal-col {
      grid-row: 1;
    }
  }
}