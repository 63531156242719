* {
  margin: 0;
  padding: 0;
}


.login {
  font-family: sans-serif;
  text-align: center;
  margin-top: 150px;
}

.principal{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
}

.form {
  max-width: 330px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  margin-top: 5px;
}

.form .form__custom-button {
  margin-top: 50px;
  background-color: rgba(0,103, 166, 1);
  color: white;
}

.img{
  margin-top: 20px;
  margin-bottom: 20px;
}