.navbar-interno {
  box-shadow: none;
  margin-top: 10px;  

  .navbar-nav {
    .active {
      .nav-link {
        color: $primary-color;
      }
    }
  
    .nav-link {
      color: $mid-gray;  
    }
  }

}

@media (min-width: 992px) {
  .navbar-interno {

    .navbar-nav {
      .nav-item {
        .nav-link {
          padding-right: 1rem;
          padding-left: 1rem;
        }
        &:first-of-type {
          .nav-link {
            padding-left: 0; 
          }
        }
      }
    }
  }

}
