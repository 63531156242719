.form-control {
  // border-radius: 50px; 
}

.file-upload-input {
  display: none;
}


// MD INPUTS & SELECTS 
.md-input {
  position: relative;
  margin-bottom: 1rem; 
  &:last-of-type {
      margin-bottom: 0; 
  }
}

.md-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 1rem !important;
  background-image: -o-linear-gradient(45deg, transparent 50%, gray 50%), -o-linear-gradient(315deg, gray 50%, transparent 50%), -o-linear-gradient(left, #ccc, #ccc);
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 19px) calc(1em + 6px), calc(100% - 15px) calc(1em + 6px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 30px;
  background-repeat: no-repeat;
}

.md-form-control, .md-select {
  font-size: 16px;
  padding: 0.6rem 0.75rem; 
  display: block;
  border: none;
  border-bottom: 2px solid #CACACA;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 8px !important;
}

.md-form-control:disabled, .md-select:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.md-form-control:focus, .md-select:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.25);
  border-color: $primary-color; 
}

.md-label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 22px;
  top: 12px;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.md-label span {
  color: #E4524F !important;
}

.md-form-control:not(:-moz-placeholder-shown) ~ label {
  top: -9px;
  left: 15px;
  font-size: 12px;
  color: $primary-color; 
  padding: 0 0.5rem;
  background-color: white;
  z-index: 9;
}

.md-form-control:not(:-ms-input-placeholder) ~ label {
  font-size: 12px;
  color: $primary-color; 
  position: absolute;
  pointer-events: none;
  left: 17px;
  top: -1px !important;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  border-bottom: 1px solid #fff;
  padding: 0px 8px;
  line-height: 0.08em;
}

.md-form-control:focus ~ label, 
.md-form-control:not(:placeholder-shown) ~ label {
  font-size: 12px;
  color: $primary-color; 
  position: absolute;
  pointer-events: none;
  left: 17px;
  top: -2px !important;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  border-bottom: 2px solid #fff;
  padding: 0px 8px;
  line-height: 0.08em;
}

.md-select:focus ~ label, 
.md-select:not([value=""]):valid ~ label {
  font-size: 12px;
  color: $primary-color; 
  position: absolute;
  pointer-events: none;
  left: 17px;
  top: -2px !important;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  border-bottom: 2px solid #fff;
  padding: 0px 8px;
  line-height: 0.08em;
  z-index: 9;
}

.md-select.is-invalid,
.md-form-control.is-invalid {
  color: #dc3545;
}
.md-select.is-invalid:focus ~ label, .md-select.is-invalid:valid ~ label,
.md-form-control.is-invalid:focus ~ label,
.md-form-control.is-invalid:valid ~ label {
  color: #dc3545;
}
.md-select.is-invalid:focus,
.md-form-control.is-invalid:focus {
  border-color: #dc3545 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.invalid-feedback {
  text-align: left;
}

.md-label-disabled {
  font-size: 12px;
  color: #044478;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 17px;
  top: -1px !important;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  border-bottom: 1px solid #fff;
  padding: 0px 8px;
  line-height: 0.08em;
}

.textarea-label {
  font-size: 12px;
  color: #044478;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-title, .form-date {
  color: $mid-gray; 
}

.form-check-inline {
  display: flex; 
  column-gap: 25px;
}

.form-control[type=file] {
  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
}

.fileInput {
  margin-top: 10px;
}

.inputFile {
	padding: 0 !important;
	border-radius: 0;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::file-selector-button {
    cursor: pointer;
		height: 100%; 
		padding: 0.375rem 1.75rem !important;
		// margin: -0.375rem -0.75rem !important 
		-webkit-margin-end: 0.75rem !important;
		// margin-inline-end: 0.75rem !important
		color: #212529;
		background-color: #e9ecef;
		pointer-events: none;
		border-color: inherit;
		border-style: solid;
		border-width: 0;
		border-inline-end-width: 1px;
		border-radius: 0;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
  }	
}