.navContent {
  padding: 15px;
  height: 100vh;

  display: flex; 
  flex-direction: column;

  .brand {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-nav {
    margin-top: 35px; 
    list-style: none;
    padding-left: 0;
    flex: 1 1 auto; 

    .sidebar-item {
      padding: 10px 16px; 
      margin-bottom: 10px;
      border-radius: 6px;

      .sidebar-link {
        color: $text-color; 
        display: flex; 
        align-items: center;
        font-weight: 500;
        font-size: 14px; 
        .link-icon {
          margin-right: 15px;
        }
      }

      &.active {
        background-color: $primary-color;
        border-radius: 6px;
        .sidebar-link {
          color: $white; 
          font-weight: 700;
        }
      }

      &:hover {
        background-color: $primary-contrast-color; 
      }

      &.active:hover {
        background-color: $primary-color; 
      }

    }

  }

  .user-dropdown {
    .profile-item {
      margin-bottom: 5px; 
      background-color: $secondary-contrast-color; 
      color: $text-color; 
      border-radius: 6px; 
      padding: 16px; 
      display: flex; 
      align-items: center;
      column-gap: 15px;
      white-space: initial;

      .profile-name {
        line-height: 1.2;
        display: block; 
        .name {
          margin-bottom: 0; 
          font-weight: 600;
          font-size: 14px; 

          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
        }
        .role {
          font-size: 12px; 
        }
      }

      .link-icon {
        color: #8692A6; 
      }
    }

    .dropdown-menu {
      position: absolute; 
      inset: auto auto 0px 0px; 
      margin: 0px; 
      transform: translate(0px, -34px);
      border: 1px solid $secondary-contrast-color; 
      width: 270px;
    }

    .dropdown-toggle {
      &:after {
        display: none; 
      }
    }

  }
  

  .sidebar-footer {
    padding: 35px 0 20px 0; 
    border-top: 1px solid $secondary-contrast-color; 

    .sidebar-link {
      color: $text-color; 
      display: flex; 
      align-items: center;
      font-weight: 400;
      // background-color: $primary-contrast-color; 
      padding: 10px 16px; 
      border-radius: 6px; 

      .link-icon {
        margin-right: 15px; 
      }
    }
  }
}