$font: 'Open Sans', sans-serif;

// Colores BASE
$white: #fff; 
$gray-dark: #121216;
$gray-predark: #121216; 
$light-gray: #e5e5e5;
$mid-gray: #696F79; 
$bg-gray: #f5f5f5; 

$text-color: #121216; 
$primary-color: #1565D8;
$secondary-color:  #00B1C9; 

$primary-contrast-color: #EDF2F9; 
$secondary-contrast-color: #E5E5E5;  

$success-color: #0BB011;
$warning-color: #FFCD5E; 
$alert-color: #F95A5A; 



// REFERENCIA
// https://appstack.bootlab.io/dashboard-default.html?theme=light
// https://htmlstream.com/preview/front-dashboard-v2.0/index.html