.wrapperPage {
  align-items: stretch;
  display: flex;
  width: 100%;
}

.sidebar {
  background-color: $white; 
  direction: ltr;
  max-width: 260px;
  min-width: 260px;
  transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
}

.sidebar.collapsed {
  margin-left: -260px;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
  width: 100%;
}

.dashboardContent {
  padding: 15px; 
  height: calc(100vh - 86.31px);
  overflow: auto;
}

.contentPage {
  padding: 30px; 
}

.w-25 {
  width: 25%;
}

.backPage {
  display: flex;
  align-items: center;
  font-size: 14px; 
  margin-bottom: 15px; 
  .material-icons-outlined {
    margin-right: 5px; 
  }
}

// Dashboard responsive
@media (max-width: 767px) {
  .main {
    overflow-y: hidden;
  }
  .navbar {
    width: 100vw;
  }
  .dashboardContent {
    max-width: 100vw;
    width: 100vw;
  }
}