.navbar-light {
  padding: 15px; 
  background-color: $white; 
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%), 0 2px 0 rgb(0 0 0 / 5%);

  display: flex;
  column-gap: 15px; 

  .leftside {
    display: flex;
    align-items: center;
    width: auto;
  }

  .custom-nav {
    border: 1px solid $secondary-contrast-color; 
    padding: 0.6rem 1rem;
    border-radius: 6px; 
    
    &:after {
      vertical-align: 0em;
      margin-left: 30px; 
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-left: 0.4em solid transparent;
    }
  }
  .btn {
    box-shadow: none;
    padding: 0.625rem 1rem 0.5rem;
  }
  
  .img-perfil {
    width: 35px;
    height: 35px;
    margin-right: 15px; 
    border: 1px solid #c5c5c5; 
    border-radius: 50px; 
  }
  
  .dropdown-item {
    min-width: 240px;
  }

}

@media (min-width: 991px) {
  .navbar-light {
    .leftside {
      width: 60%; 
    }
  }
}