.carga-documento-section {
  background-color: #F6F6F6; 
  padding: 20px; 

  .carga-documento-content {
    text-align: center;

    .material-icons-outlined {
      font-size: 72px; 
      color: #C4C4C4;
    }
  }

  .text {
    margin-bottom: 15px; 
    font-size: 16px;
    color: #676F7B; 
  }

  .text-small {
    margin-bottom: 10px;
  }

  .text-alert {
    margin-bottom: 0;
    font-size: 12px; 
  }

  .btn {
    margin-bottom: 20px; 
  }
}