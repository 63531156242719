#paginador {
  margin-top: 30px; 
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
	
  label {
		display: flex;
		top: 0;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		pointer-events: auto;

		.custom-select {
      width: 70px;
			margin-left: 0.3rem;
			margin-right: 0.4rem;
			height: 25px;
			padding: 0rem 0.2rem 0rem 0.2rem!important;
			color: #495057;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			-webkit-appearance: auto;
			-moz-appearance: auto;
			background-image: none;
			background-position: calc(100% - 20px) calc(1em + 1px),calc(100% - 15px) calc(1em + 1px),calc(100% - 2.5em) 0.5em;
			background-size: 5px 5px,5px 5px,1px 1.5em;
			background-repeat: no-repeat;
			font-size: 12px ;
    }
  }
	
	.page-item {
	  .page-link {
			font-size: 12px;
			background-color: #fff;
			color: $mid-gray;
			height: 100%;
			width: 25px ;
			height: 25px;
			font-weight: 500;
			margin-left: -1px;
			display: flex;
			justify-content: center;
			overflow: hidden;
			align-items: center;
      margin: 0 2px; 

      &:hover {
        background-color: $primary-contrast-color; 
      }
    }
    &.active {
      .page-link {
        background-color: $primary-color;
        box-shadow: none;
        font-weight: 600 !important;
        color: #fff; 
      }
    }
  }

  .pagination {
    margin-bottom: 0;
    justify-content: end;
    margin-top: 10px; 
  }

}

@media (min-width: 991px) {
  #paginador {
    flex-direction: row;
    justify-content: space-between;

    .pagination {
      margin-top: 0; 
    }
  }

}