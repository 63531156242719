body {
  font-family: $font;  
  font-size: 14px; 
  font-weight: 400;
  height: 100%; 
  opacity: 1!important;
  // overflow-y: scroll;
  background-color: #EDF2F9; 
}

.shadow-border {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  border: 1px solid #dee2e6;
}

h1 {
  font-size: 26px; 
  margin-bottom: 0; 
  font-weight: 600;
}

@media (min-width: 991px) {
  h1 {
    font-size: 28px; 
  }
}

//  Scrollbar Style
::-webkit-scrollbar-track {
  border-radius: 10px; 
  background-color: #fff; 
}

::-webkit-scrollbar {
  height: 4px;
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px; 
  background: rgba(37,75,98,0.3);
}

.accordion-button:not(.collapsed) {
  color: $gray-dark;
}

[aria-expanded="true"] {
  .icon-arrow:before {
    color: red;
    content: "expand_less"; 
  }
}

.margin {
  margin-top: 38px; 
}
.m-left {
  margin-left: 28px; 
}

#container-file .pdf-file {
  width: 80%;
  height: 730px;
}