.info-doc {
  .title {
    font-weight: 600;
    color: $primary-color; 
  }
  .text {
    font-weight: 500;
    font-size: 16px; 
  }
  margin-bottom: 15px; 
}