.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dialog-option-container{
  margin: 25px 0;
  padding-left: 20px;
  padding-right: 20px;
}

.dialog-option-status{
  margin: 15px 0;
  margin-top: 30px;
  width: 100px;
}

.form-root{
  display: 'flex';
  flex-wrap: 'wrap';
}

.ck-editor__editable {
  min-height: 200px;
}
.seleccion {
  color: '#ffff';
  background-color: '#0067a6'
}
.iconListColorTable {
  color: #18202c;
}
.botonHeaderList{
  margin-right: '20px';
}
.botonEliminar{
  color: #fe0000;
}
.botonVerRecibos{
  color: #0067a6;
  margin-top: 5px;
}
.iframeVisor{
  height: 650px;
}
.iframeVisorEmpleado{
  height: 730px;
  align-content: center;
  display: flex;
}
.dialogPaper{
  min-height: '80vh';
  max-height: '80vh';
}
.pdfIframe{
  display: flex;
  align-items: center;
}

.marginApariencia{
  margin-top: 150px;
}

.mariginGridSelectApariencia{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.mariginSelectImport{
  margin-top: 50px;
}

.pdf-background {
  padding-top: 25px;
  min-height: 400px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
/*   background: gainsboro none repeat scroll 0% 0%; */
  z-index: -1;
}



.pdf-parent {
  position: relative;
  width: 97%;
  margin: auto;
  z-index: 1;
}

.material-table-container .MuiPaper-root{
  box-shadow: none !important;
}
.table-style{
  padding: 40px;
  width: 300px;
}

.bton-accion{
background: #1565D8;
border-radius: 6px;
}

.login,
.image {
  min-height: 100vh;
}
.bg-image {
  background-image: url('./images/loginImage.png');
  background-size: cover;
  background-position: center;
}

