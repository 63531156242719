.card-item {
  border: 1px solid $secondary-contrast-color; 
  border-radius: 8px; 
  margin-bottom: 15px; 
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $primary-contrast-color;
  }

  .detail {
    font-size: 14px; 
    color: $mid-gray; 
  }

  .lote-item {
    padding: 20px; 
    color: $mid-gray; 
    display: flex;
    flex-direction: column; 
    column-gap: 20px;
  }
  
  .lote-info {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;

    .col-item {
      display: grid; 
      .title {
        font-weight: 700;
      }
      .info {
        font-weight: 400;
      }
    }
    
  }

  .card-actions {
    padding: 0 20px;  
  }
}

@media (min-width: 991px) {
  .card-item {
    .lote-info {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (min-width: 1280px) {
  .card-item {
    .lote-info {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (min-width: 1440px) {
  .card-item {
    .lote-info {
      grid-template-columns: 20% 12% 18% 18% 18%;
    }
  }
}