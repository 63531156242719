.table {
  tbody, td, tfoot, th, thead, tr {
    vertical-align: middle;
  }

  th {
    font-weight: 600;
  }
  tr {
    font-weight: 400;
  }
}

.table-actions {
  display: flex; 
  column-gap: 15px;
}