.loginPage {
  display: grid; 
  grid-template-columns: 1fr;
  .content-left{
    display: none; 
    .img-fluid {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }
  .content-right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    &__form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .loginForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 92vh;
      width: 50%; 

      .btn {
        margin-top: 20px; 
      }
      .forgotPassword{
        margin-top: 20px; 
        text-align: center;
      }
      .img-brand {
        width: 240px;
        margin-bottom: 40px; 
        display: flex;
        justify-content: start;
      }
      h1 {
        font-size: 26px;
        font-weight: 800;
      }
    }

    .poweredby {
      height: 8vh;
      text-align: right;
      display: flex; 
      align-items: center;
      justify-content: end;
      padding-right: 20px; 
    }
  }
}

@media (min-width: 991px) {
  .loginPage {
    display: grid; 
    grid-template-columns: 1fr 1fr;
    .content-left{
      display: block; 
    }
  }
}